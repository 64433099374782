import { fetchPost ,fetchGet} from '../index';

// 查询table数据接口
export const getQueryList = ( params = {}): Promise<any> => {
	return fetchPost('/claimapi/salesIncentiveProgram/vinBasedEnquiry', params)
}

// 点击车架号，查询兼容性
export const vinBasedEnquiryCompatibility= ( params = {}): Promise<any> => {
	return fetchPost('/claimapi/salesIncentiveProgram/vinBasedEnquiryCompatibility', params)
}

//获取年款
export const getModalYear = (): Promise<any> => {
	return fetchGet('/vapi/noAuth/get_property_lov?property=modelYear')
}

//获取车型分类
export const getTypeClass = (): Promise<any> => {
	return fetchGet('/	vapi/noAuth/get_property_lov?property=typeClass')
}