
import {
  defineComponent,
  ref,
  reactive,
  toRefs,
  onMounted,
  nextTick,
  computed,
  h,
} from "vue";
import {
  getQueryList,
  vinBasedEnquiryCompatibility,
  getModalYear,
  getTypeClass,
} from "@/API/claim/claimVINBased";
import { message } from "ant-design-vue";
import downloadFile from "@/utils/claim/downloadFile";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import { useI18n } from "vue-i18n";
import useEventbus from "@/hooks/checking/spotChecking/useEventbus";
interface QueryListType {
  vinNo: string | null;
  typeClassCodes: string[];
  materialNo: string | null;
  modelName: string | null;
  modelYear: string | undefined;
  wholesaleSources: string[];
  wholesaleTime: string[];
  retailTime: string[];
  dealerCode: string | null;
  dealerNameCn: string | null;
  dealerNameEn: string | null;
}
interface SearchListType {
  vinNo: string | null;
  typeClassCodes: string[];
  materialNo: string | null;
  modelName: string | null;
  modelYear: string | undefined;
  wholesaleSources: string[];
  wholesaleBegin: string | null;
  wholesaleEnd: string | null;
  retailBegin: string | null;
  retailEnd: string | null;
  dealerCode: string | null;
  dealerNameCn: string | null;
  dealerNameEn: string | null;
  size: number;
  current: number;
}
interface TableType {
  modelName: string;
  modelYear: string;
  programCode: string;
  programId: string;
  programNameCn: string;
  programNameEn: string;
  retailDate: string;
  vinNo: string;
  wholesaleDate: string;
}
export default defineComponent({
  components: {},
  setup() {
    const { t, locale } = useI18n();
    const { customEmit } = useEventbus();
    const store = useStore();
    const isDealer = ref(true);
    if ((store.state.user as any).legalEntity.entityCode !== "Dealer") {
      isDealer.value = false;
    }
    console.log(isDealer.value, "isDealer");
    //点击右侧表格的的行列
    const columnIndex = ref(-1);
    const rowIndex = ref(-1);

    const router = useRouter();

    //是否点击左边的车辆表格
    const isClick = ref(true);
    const clickVinNO = ref("");
    const clickDealerCode = ref("");

    //分页
    const pagination = reactive({
      total: 0,
      currentPage: 1,
      pageSize: 20,
    });

    // const dealerCode = ref("193120320");
    const { state } = useStore();
    const dealerCode = ref("");
    dealerCode.value = (state.user as any).organization.entityCode;
    const user = (
      (state.user as any).legalEntity.entityCode as string
    ).toLowerCase();
    //获取sideNavCollapsed，判断左右表格宽度
    // const store = useStore();
    const leftTableWidth = computed(() => {
      if (store.state.sideNavCollapsed) {
        return (window.innerWidth - 300) * 0.3;
      } else {
        return (window.innerWidth - 80) * 0.3;
      }
    });

    const rightTableWidth = computed(() => {
      if (store.state.sideNavCollapsed) {
        return (window.innerWidth - 420) * 0.55;
      } else {
        return (window.innerWidth - 200) * 0.55;
      }
    });

    const searchCon = ref();
    const tableHeight = window.innerHeight - 345;
    // 车型分类list假值
    const typeClassCodesList = ref<object>([]);
    // 年款假值
    const modelYearList = ref<object>([]);
    // 批发资源假值
    const wholesaleSourcesList = reactive<Array<any>>([
      { id: "10012", name: "MBCL" },
      { id: "10010", name: "BBAC" },
      { id: "10011", name: "FBAC" },
      // { id: "DENZA", name: "DENZA" },
    ]);

    // const wholesaleSourcesList = reactive<Array<any>>(store.state.user.entityList);

    //筛选条件，各个信息框的内容
    const queryListParams = reactive<QueryListType>({
      vinNo: "",
      typeClassCodes: [],
      materialNo: "",
      modelName: "",
      modelYear: undefined,
      wholesaleSources: [],
      wholesaleTime: [],
      retailTime: [],
      dealerCode: "",
      dealerNameCn: "",
      dealerNameEn: "",
    });
    // 设置搜索值
    const params = reactive<SearchListType>({
      vinNo: "",
      typeClassCodes: [],
      materialNo: "",
      modelName: "",
      modelYear: undefined,
      wholesaleSources: [],
      wholesaleBegin: "",
      wholesaleEnd: "",
      retailBegin: "",
      retailEnd: "",
      dealerCode: "",
      dealerNameCn: "",
      dealerNameEn: "",
      current: 0,
      size: 20,
    });
    //显示的表格数据
    const columns = reactive([
      {
        title: t("dealerVINBasedEnquiry.vinNO"),
        dataIndex: "vinNo",
        width: 170,
        fixed: "left",
        // customRender: (text: any, record: any, index: any) => {
        //   // console.log(record);
        //   // console.log(index);
        //   // console.log(text);
        //   return "xx";
        // },
      },
      {
        title: t("dealerVINBasedEnquiry.wholesaleDate"),
        dataIndex: "wholesaleDate",
        width: 120,
      },
      {
        title: t("dealerVINBasedEnquiry.retailDate"),
        dataIndex: "retailDate",
        width: 120,
      },
      {
        title: t("dealerVINBasedEnquiry.vehicleName"),
        dataIndex: "modelName",
        width: 320,
        // customCell: (record: any, rowIndex: any) => {
        //   return { style: { color: "#FF0000", background: "#fafafa" } }; //return 想要设置的样式
        // },
      },
      {
        title: t("dealerVINBasedEnquiry.modelYear"),
        dataIndex: "modelYear",
        width: 120,
      },
    ]);
    const queryListData = ref<TableType[]>([]);
    //用于判断查询按钮是否可点
    // const isDisabled = computed(() => {
    //   const values = Object.values(queryListParams);
    //   const sign = ref(true);

    //   for (let i = 0; i < values.length; i++) {
    //     if (i === 2 || i === 6) {
    //       if ((values[i] as []).length > 0) {
    //         sign.value = false;
    //       }
    //     } else {
    //       if (values[i] !== null && (values[i] as string).length>0) {
    //         sign.value = false;
    //       }
    //     }
    //   }
    //   return sign;
    // });
    //筛选
    const isOpen = ref(false);
    // 获取params
    const getParams = () => {
      params.vinNo = "";
      params.typeClassCodes = [];
      params.materialNo = "";
      params.modelName = "";
      params.modelYear = undefined;
      params.wholesaleSources = [];
      params.wholesaleBegin = "";
      params.wholesaleEnd = "";
      params.retailBegin = "";
      params.retailEnd = "";
      params.dealerCode = "";
      params.dealerNameCn = "";
      params.dealerNameEn = "";

      params.current = pagination.currentPage - 1;
      params.size = pagination.pageSize;

      if (queryListParams.vinNo !== null && queryListParams.vinNo !== "") {
        params.vinNo = queryListParams.vinNo;
      }

      if (
        queryListParams.typeClassCodes &&
        queryListParams.typeClassCodes.length > 0
      ) {
        params.typeClassCodes = queryListParams.typeClassCodes;
      }

      if (
        queryListParams.materialNo !== null &&
        queryListParams.materialNo !== ""
      ) {
        params.materialNo = queryListParams.materialNo;
      }

      if (
        queryListParams.modelName !== null &&
        queryListParams.modelName !== ""
      ) {
        params.modelName = queryListParams.modelName;
      }

      if (
        queryListParams.modelYear !== undefined &&
        queryListParams.modelYear !== ""
      ) {
        params.modelYear = queryListParams.modelYear;
      }

      if (
        queryListParams.wholesaleSources &&
        queryListParams.wholesaleSources.length > 0
      ) {
        params.wholesaleSources = queryListParams.wholesaleSources;
      } else {
        params.wholesaleSources = [];
        for (let i = 0; i < wholesaleSourcesList.length; i++) {
          params.wholesaleSources.push(wholesaleSourcesList[i].id);
        }
      }

      if (queryListParams.wholesaleTime.length > 0) {
        params.wholesaleBegin = queryListParams.wholesaleTime[0];
        params.wholesaleEnd = queryListParams.wholesaleTime[1];
      }

      if (queryListParams.retailTime.length > 0) {
        params.retailBegin = queryListParams.retailTime[0];
        params.retailEnd = queryListParams.retailTime[1];
      }

      if (
        queryListParams.dealerCode !== null &&
        queryListParams.dealerCode !== ""
      ) {
        params.dealerCode = queryListParams.dealerCode;
      }

      if (
        queryListParams.dealerNameCn !== null &&
        queryListParams.dealerNameCn !== ""
      ) {
        params.dealerNameCn = queryListParams.dealerNameCn;
      }

      if (
        queryListParams.dealerNameEn !== null &&
        queryListParams.dealerNameEn !== ""
      ) {
        params.dealerNameEn = queryListParams.dealerNameEn;
      }

      if (user === "dealer") {
        params.dealerCode = dealerCode.value;
      }
    };
    // 重置
    const clearQueryListParams = () => {
      queryListParams.vinNo = "";
      queryListParams.typeClassCodes = [];
      queryListParams.materialNo = "";
      queryListParams.modelName = "";
      queryListParams.modelYear = undefined;
      queryListParams.wholesaleSources = [];
      queryListParams.wholesaleTime = [];
      queryListParams.retailTime = [];
      queryListParams.dealerCode = "";
      queryListParams.dealerNameCn = "";
      queryListParams.dealerNameEn = "";
    };

    //用于循环slot显示右侧表格表头
    const slotArray: Array<any> = [];
    const slotValueArray: Array<any> = [];

    const compatibleData = ref<any[]>([]);
    const compatibleColumns = ref<any[]>([]);

    //根据筛选框信息，查询表格
    const getTableData = () => {
      compatibleData.value = [];
      compatibleColumns.value = [];
      getParams();
      getQueryList(params).then((res: any): void => {
        queryListData.value = res.data;
        pagination.total = res.totalNum;
        isOpen.value = false;
        // if (res && res.length > 0) {
        //   const customerColumn = ['dealerCode', 'dealerNameCn', 'eligiblePeriodStr', 'offerTypeName', 'optionName', 'vinNo', 'model', 'inParagraph', 'payRound', 'checkResult', 'predictAmount', 'price'];
        //   queryListData.value.length && Object.assign(columns, setColumnWidth(columns, queryListData.value, customerColumn))
        // }
      });
    };

    const quertTable = () => {
      pagination.currentPage = 1;
      getTableData();
    };

    //处理兼容性表格的数据，生成正常的table数据
    const handelData = (data: any, vinNo: string) => {
      console.log(data);
      compatibleColumns.value = [];
      compatibleData.value = [];
      compatibleColumns.value.push({
        title: vinNo,
        dataIndex: "vinNo",
        width: 260,
        fixed: "left",
        slots: { customRender: "vinNo" },
        customCell: (record: any, rowIndex: any) => {
          console.log("isDealer.value", isDealer.value);
          return isDealer.value
            ? {
                onClick: (e: any) => {
                  const programCode = record.programCode;
                  router.push({
                    name: "Sales Incentive Claim",
                    params: { programCode: programCode },
                  });
                },
              }
            : {};
        },
      });

      const codeArray: Array<string> = [];

      for (let i = 0; i < data.program.length; i++) {
        const one = data.program[i];
        slotArray.push({
          programId: one.programId,
          programCode: one.programCode,
          programNameCn: one.programNameCn,
          columnIndex: i,
        });
        slotValueArray.push({
          valueId: "value" + one.programId,
          valueCode: "value" + one.programCode,
          programNameCn: "value" + one.programNameCn,
          programCode: one.programCode,
        });
        codeArray.push(one.programCode);
        compatibleColumns.value.push({
          dataIndex: one.programCode,
          width: 155,
          slots: {
            title: one.programCode,
            customRender: "value" + one.programCode,
          },
          // customCell: (record: any, rowIndex: any) => {
          //   // console.log(record)
          //   const array = Object.keys(record);
          //   console.log(array)
          //   for (let i=0;i<array.length;i++){
          //     console.log(i)
          //     if (record[array[i]] === "N"){

          //       return { style: { color: "#FF0000", background: "#fafafa" } };
          //     }

          //     if (record[array[i]] === "Y"){
          //       return { style: { color: "#00FF00", background: "#fafafa" } };
          //     }
          //     return {}; //return 想要设置的样式

          //   }
          // },
        });
      }

      const rowData: Array<any> = [];

      /*
          {
            2021Q2V205_000:Y
          }
        
        */

      for (let i = 0; i < data.program.length; i++) {
        const oneData = {};
        const one = data.program[i];
        oneData["vinNo"] = one.programCode;
        oneData["programNameCn"] = one.programNameCn;
        oneData["programCode"] = one.programCode;
        oneData["rowIndex"] = i;

        for (let j = 0; j < codeArray.length; j++) {
          if (i > j - 1) {
            oneData["value" + codeArray[j]] = "";
          } else {
            oneData["value" + codeArray[j]] = "Y";
          }
        }

        for (let i = 0; i < one.conflict.length; i++) {
          const code = "value" + one.conflict[i].programCode;

          for (const key in oneData) {
            if (key === code && oneData[key] !== "") {
              oneData[key] = "N";
            }
          }
        }

        compatibleData.value.push(oneData);
      }
    };

    //获取dealer界面兼容性
    const getCompatibleDataDealer = (vinNo: string) => {
      const param = {
        vinNo: vinNo,
        dealerCode: dealerCode.value,
      };
      clickVinNO.value = vinNo;
      isClick.value = false;

      vinBasedEnquiryCompatibility(param).then((res: any): void => {
        handelData(res, vinNo);
      });
    };
    //获取兼容性
    const getCompatibleData = (vinNo: string, dealerCode: string) => {
      const param = {
        vinNo: vinNo,
        dealerCode: dealerCode,
      };
      clickVinNO.value = vinNo;
      clickDealerCode.value = dealerCode;
      isClick.value = false;
      vinBasedEnquiryCompatibility(param).then((res: any): void => {
        handelData(res, vinNo);
      });
    };

    //左侧表格点击事件
    const customRow = (record: any): { onClick: Function } => {
      return {
        onClick: (e: any) => {
          //e.target.previousSibling.previousSibling.previousSibling.nodeType
          columnIndex.value = -1;
          rowIndex.value = -1;
          if (locale.value === "en") {
            getCompatibleData(record.vinNo, record.dealerCode);
          } else {
            getCompatibleDataDealer(record.vinNo);
          }
        },
      };
    };

    let preCell: any = null;
    let preCellClass = "";
    //右侧表格点击事件
    const rightCustomRow = (record: any): { onClick: Function } => {
      return {
        onClick: (e: any) => {
          let count = 0;
          let div = e.target.parentElement;
          if (preCell !== null) {
            preCell.className = preCellClass;
          }
          if (e.target.innerText !== "") {
            preCellClass = e.target.className;
            preCell = e.target;
            e.target.className += " select-border";
            // div.className = 'select-border'
          }

          if (e.target.innerText === "") {
            columnIndex.value = -1;
            rowIndex.value = -1;
            return;
          }
          while (
            div.innerText === "" ||
            div.innerText === "Y" ||
            div.innerText === "N"
          ) {
            count++;
            div = div.previousSibling;
            console.log(div);
          }

          if (count === 0) {
            return;
          }

          columnIndex.value = count - 2;
          rowIndex.value = record.rowIndex;

          //e.target.previousSibling.previousSibling.previousSibling.nodeType
          console.log(record);
        },
      };
    };
    //导出功能
    const exportResult = () => {
      if (clickVinNO.value === "") {
        message.error(
          locale.value === "en" ? "Please select VIN!" : "请选择导出的车架号！"
        );
        return;
      }

      // const paramsConfig = {
      //   url: `/claimapi/salesIncentiveProgram/exportExcel?vinNo=${clickVinNO.value}&dealerCode=${dealerCode.value}"`,
      //   method: "post",
      //   // params: params,
      //   fileName: `Sales_Incentive_Claim_Export_Template.xlsx`,
      // };

      // const option = {
      //   dealerCode: dealerCode.value,
      //   vinNo:clickVinNO.value
      // }
      if (locale.value === "en") {
        const option = {
          dealerCode: clickDealerCode.value,
          vinNo: clickVinNO.value,
        };

        const paramsConfig = {
          url: `/claimapi/salesIncentiveProgram/exportExcel`,
          method: "post",
          params: option,
          // fileName: `Sales_Incentive_Claim_Export_Template.xlsx`,
        };
        downloadFile(paramsConfig, "application/vnd-excel; char-set=UTF-8");
      } else {
        const option = {
          dealerCode: dealerCode.value,
          vinNo: clickVinNO.value,
        };

        const paramsConfig = {
          url: `/claimapi/salesIncentiveProgram/exportExcel`,
          method: "post",
          params: option,
          // fileName: `Sales_Incentive_Claim_Export_Template.xlsx`,
        };
        downloadFile(paramsConfig, "application/vnd-excel; char-set=UTF-8");
      }
    };
    const refreshTable = () => {
      getTableData();
    };

    //获取年款下拉框信息
    const queryModalYear = () => {
      getModalYear().then((res: any): void => {
        const array = [];

        for (let i = 0; i < res.length; i++) {
          if (res[i] === null || res[i] === "") {
            continue;
          }
          array.push({
            id: res[i],
            name: res[i],
          });
        }
        modelYearList.value = array;
      });
    };
    //获取车型分类下拉框信息
    const queryTypeClass = () => {
      getTypeClass().then((res: any): void => {
        const array = [];
        for (let i = 0; i < res.length; i++) {
          if (res[i] === null || res[i] === "") {
            continue;
          }
          array.push({
            id: res[i],
            name: res[i],
          });
        }

        typeClassCodesList.value = array;
      });
    };

    //更改分页页签
    const pageChange = (page: number) => {
      pagination.currentPage = page;
      getTableData();
    };
    //改变每页大小
    const sizeChange = (page: number, pageSize: number) => {
      pagination.pageSize = pageSize;
      pagination.currentPage = 1;
      getTableData();
    };

    onMounted(() => {
      getTableData();
      queryModalYear();
      queryTypeClass();
    });

    return {
      locale,
      isDealer,
      quertTable,
      user,
      ...toRefs(pagination),
      pageChange,
      sizeChange,
      isClick,
      rightCustomRow,
      columnIndex,
      rowIndex,
      slotValueArray,
      rightTableWidth,
      slotArray,
      customRow,
      compatibleColumns,
      compatibleData,
      searchCon,
      tableHeight,
      isOpen,
      columns,
      typeClassCodesList,
      modelYearList,
      wholesaleSourcesList,
      queryListParams,
      queryListData,
      params,
      getParams,
      getTableData,
      refreshTable,
      clearQueryListParams,
      exportResult,
      leftTableWidth,
      // isDisabled
    };
  },
});
