import mitt from 'mitt';

const emitter: mitt.Emitter = mitt();

// 自定义触发器
const customEmit = (eventName: any, arg?: any) => {
    emitter.emit(eventName, arg)
}

// 自定义接收器
const customOn = (eventName: any, arg?: any) => {
    emitter.on(eventName, arg)
}
// 清除自定义接收器
const customOff = (eventName: any) => {
    emitter.off(eventName)
}
const useEventbus = () => {
    return {
        customEmit,
        customOn,
        customOff
    }
}
export default useEventbus
